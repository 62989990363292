<template>
  <div>
    <!-- picBE205166DC7D5D302BE55C82189C69AF.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/prof-brickkeeper-cooks-up-some-fun.jpg">

    <p>
      The LEGO Universe News Network recently warmed up with Professor Brickkeeper in the Creation Lab! Here's what he had to say:
    </p>
    <p>
      <i>
        Greetings! Come in out of the snow! Care for a warm bricky-bun? There's a 97 percent chance that one of these would hit the spot on such a cold day! I got the recipe from a friend who traveled to-- say, I bet you'd enjoy meeting him yourself! He's coming soon to
        <a href="http://mln.lego.com/en-us/Publicview/Prof%20Brickkeeper.aspx" target="_blank">My LEGO Network</a>!
      </i>
    </p>
    <p>
      <i>You <b>do</b> have a Creation Lab badge for My LEGO Network, right? That badge will help you befriend my buddy and the other --- Woops! Hey! Heel! That's not your bun! Down boy! Heel! Heel! ... Sorry about that! My new robo-dog does love a bricky-bun!</i>
    </p>
    <p>
      <i>Now where was I? Oh yes! More new LEGO Universe networkers will be featured soon on My LEGO Network! Get your Creation Lab badge if you haven't already, and I'll see you there!</i>
    </p>
  </div>
</template>
